import LocalStorage from "./localStorage";

export const verifyEmail = (text) => {
  const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
  return regex.test(text);
};

export const returnFormatNumber = (value: string | number = 0, decimals = 0) => {
  if (value === "") {
    return "";
  }

  return parseFloat(String(value))
    .toFixed(decimals)
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const returnCurrentLink = (route) =>
  `http${process.env.NODE_ENV === "production" ? "s" : ""}://${
    window?.location?.host
  }${route}`;

export const removeChild = (childTarget) => {
  if (childTarget?.parentNode?.removeChild) {
    childTarget?.parentNode?.removeChild?.(childTarget);
  }
};

export const currentLang = () => {
  const myLang = LocalStorage(false).getItem("defaultLanguage");

  return (myLang || navigator?.languages?.[0] || navigator?.language)
    .toLowerCase()
    .indexOf("en") === 0
    ? "en"
    : "es";
};

export const returnApiError = (body: any): string => {
  return String(body?.originalError?.message || body?.data || body?.message)
}