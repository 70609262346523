import { makeStyles } from "@mui/styles";
import { colors, Commons } from "styleguide";

const styles = makeStyles({
  containerBanner: {
    ...Commons.flexRow,
    backgroundColor: colors.black,
    width: "100%",
    maxWidth: 1080,
    aspectRatio: "3 / 1",
    margin: "0 auto",
    borderRadius: 18,
    padding: "30px 60px 180px 60px",
    boxSizing: "border-box",
    backgroundImage: "linear-gradient(to bottom, #6758f3, black)",
    [Commons.smallQuery]: {
      padding: 12,
      flexDirection: "column-reverse",
      marginTop: 0,
      aspectRatio: 'unset'
    },
  },
  colInfo: {
    flex: 1,
    [Commons.smallQuery]: {
      marginBottom: 30,
    },
  },
  infoContainer: {
    backgroundColor: `${colors.white}90`,
    borderRadius: 18,
    padding: 12,
    "& h2": {
      margin: "12px 0",
      fontSize: 30,
      color: colors.secondary,
    },
    [Commons.smallQuery]: {
      marginBottom: 30,
    },
  },
  gridContainer: {
    margin: "0 auto",
    marginTop: -150,
    position: "relative",
    maxWidth: 1200,
    [Commons.smallQuery]: {
      marginTop: -30,
    },
  },
  itemSquare: {
    margin: "12px !important",
    width: "calc(50% - 24px)",
    borderRadius: 12,
    backgroundImage: `linear-gradient(135deg, black, ${colors.themeColor})`,
    position: "relative",
    padding: 12,
    [Commons.smallQuery]: {
      width: "calc(100% - 12px)",
      margin: "6px !important",
    },
  },
  itemSquareMidleMobile: {
    [Commons.smallQuery]: {
      width: "calc(50% - 12px)",
      margin: "6px !important",
    },
  },
  divisionSquare: {
    minHeight: 90,
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    borderRadius: 12,
    padding: 12,
    [Commons.smallQuery]: {
      minHeight: "unset",
    },
    "&:nth-child(2)": {
      marginTop: 12,
    },
  },
  labelItemSquare: {
    color: colors.white,
    fontSize: 24,
    margin: "12px 0 0 0",
    whiteSpace: 'nowrap',
    [Commons.smallQuery]: {
      fontSize: 16,
    },
  },
  valueItemSquare: {
    ...Commons.ellipsis,
    color: colors.white,
    textAlign: "right",
    fontSize: "3em",
    fontWeight: "500",
    margin: 0,
    [Commons.smallQuery]: {
      fontSize: "2em",
    },
  },
  imageRanking: {
    display: "block",
    ...Commons.returnSquare(300),
    marginLeft: 60,
    position: "relative",
    [Commons.smallQuery]: {
      ...Commons.returnSquare(180),
      margin: "0 auto",
      marginBottom: 24,
    },
  },
  containerImageLogo: {
    ...Commons.flexCenter,
    position: "absolute",
    width: "100%",
    height: "auto",
    aspectRatio: "1 / 1",
    borderRadius: "50%",
    border: `6px solid ${colors.white}`,
    boxShadow: colors.shadowWhiteInset,
    padding: 60,
    backgroundColor: colors.black,
    boxSizing: "border-box",
    zIndex: 0,
    [Commons.smallQuery]: {
      position: "relative",
      padding: 30,
    },
  },
  imageLogo: {
    width: "120%",
    height: "auto",
    aspectRatio: "1 / 1",
    position: "absolute",
    zIndex: 0,
  },
  overlayVideo: {
    position: "absolute",
    width: "150%",
    height: "150%",
    borderRadius: "50%",
    backgroundColor: "transparent",
    zIndex: 10,
    left: "-25%",
    top: "-25%",
  },
  scrollTerms: {
    overflow: "auto",
    maxHeight: 198,
    backgroundColor: "rgba(255, 255, 255, 0.15)",
    borderRadius: 12,
    padding: "0 12px",
    marginTop: 12,
  },
  textTerms: {
    fontSize: 18,
    color: colors.white,
    [Commons.smallQuery]: {
      fontSize: 12,
    },
  },
  usersList: {
    overflow: "auto",
    maxHeight: 198,
    marginTop: 12,
  },
  itemUser: {
    ...Commons.flexRow,
    backgroundColor: "rgba(255, 255, 255, 0.25)",
    borderRadius: 12,
    padding: "6px 12px",
    marginBottom: 12,
    border: `1px solid rgba(255, 255, 255, 0.5)`,
    "& div": {
      ...Commons.flexCenter,
      borderRadius: 12,
      backgroundColor: colors.white,
      color: colors.black,
      fontWeight: "600",
      minWidth: 24,
      marginRight: 12,
    },
    "& p": {
      flex: 1,
      color: colors.white,
    },
  },
});

export default styles;
