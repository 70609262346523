import { useEffect, useState } from "react";

import { firestoreService } from "utils/firebase";
import Model from "hooks/Model";

interface ChannelServicesProps {
  channel: string;
}

interface ChannelInfoProps {
  name: string;
  description: string;
  image: string;
}

const ChannelServices = ({channel}: ChannelServicesProps) => {
  const [channelInfo, setChannelInfo] = useState<ChannelInfoProps>();
  const [recommendedVideos, setRecommendedVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  const getChannelInfo = async (owner: string) => {
      if (owner) {
        const ownerInfoResponse = (
          await firestoreService.getDoc("channels", owner)
        ).data() as unknown as ChannelInfoProps;

        setChannelInfo(ownerInfoResponse);
      }
  };

  const getRecommendedVideos = async (owner: string) => {
      if (owner) {
        const recommendedVideosResponse = [];
        const getVideosResponse = await firestoreService.getQuery("videos", [
          { label: "owner", condition: "==", value: owner },
        ]);

        getVideosResponse.forEach((video) =>
          recommendedVideosResponse.push({
            ...(video.data() || {}),
            id: video.id,
          })
        );

        setRecommendedVideos(recommendedVideosResponse);
      }
  };

  const firstLoad = async () => {
    try {
      Model.setData("loading", true);
      setLoading(true)
      if (channel) {
        await getChannelInfo(channel);
        await getRecommendedVideos(channel);
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    } finally {
      Model.setData("loading", false);
      setLoading(false)
    }
  };


  useEffect(() => {
    firstLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel]);

  return {
    recommendedVideos,
    channelInfo,
    loading
  }
}

export default ChannelServices;
