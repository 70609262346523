import * as React from "react";
import { useContext, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { detectIncognito } from "detectincognitojs";

import LocalStorage from "utils/localStorage";
import { Store } from "hooks/main_store";
import Model from "hooks/Model";

import Page404Screen from "./page404";
import OlderVerificationScreen from "./older-verification";

import HomeScreen from "./home";
import AccessAccountScreen from "./home/AccessAccount";

import SendScreen from "./send";
import ReceiveScreen from "./receive";
import HistoryListScreen from "./history";
import LegalScreen from "./legal/renderLegalDoc";

import ProfileVideosScreen from "./vwin/ProfileVideos";
import DetailVideoScreen from "./vwin/DetailVideo";
import RankingScreen from "./vwin/Ranking";

const userRouter = createBrowserRouter(
  [
    {
      path: "/",
      element: <HomeScreen />,
    },
    {
      path: "/send",
      element: <SendScreen />,
    },
    {
      path: "/receive",
      element: <ReceiveScreen />,
    },
    {
      path: "/history",
      element: <HistoryListScreen />,
    },
    {
      path: "/access-account",
      element: <AccessAccountScreen />,
    },
    {
      path: "/detail-video",
      element: <DetailVideoScreen />,
    },
    {
      path: "/ranking",
      element: <RankingScreen />,
    },
    {
      path: "/profile-videos",
      element: <ProfileVideosScreen />,
    },
    {
      path: "/legal",
      element: <LegalScreen />,
    },
    {
      path: "*",
      element: <Page404Screen />,
    },
  ],
  {
    basename: "/",
  }
);

const guestRouter = createBrowserRouter(
  [
    {
      path: "/",
      element: <HomeScreen />,
    },
    {
      path: "/detail-video",
      element: <DetailVideoScreen />,
    },
    {
      path: "/profile-videos",
      element: <ProfileVideosScreen />,
    },
    {
      path: "/legal",
      element: <LegalScreen />,
    },
    {
      path: "*",
      element: <Page404Screen />,
    },
  ],
  {
    basename: "/",
  }
);

const olderVerificationRouter = createBrowserRouter(
  [
    {
      path: "*",
      element: <OlderVerificationScreen />,
    },
  ],
  {
    basename: "/",
  }
);

function Routes() {
  const { state } = useContext(Store);

  const initialLoad = async () => {
    try {
      const result = await detectIncognito();

      const isOlder = LocalStorage(result.isPrivate).getItem("isOlder");

      if (isOlder === 'true') {
        Model.setData("isOlder", true);
      }
    } catch (error) {
      Model.updateAlerts({
        message: error?.message || error,
        variant: "error",
      });
    }
  }

  useEffect(() => {
    initialLoad()
  }, [])

  return (
    <RouterProvider router={state?.infoUser?.uid ? userRouter : (state?.isOlder ? guestRouter : olderVerificationRouter)} />
  );
}

export default Routes;
