const texts = {
  translation: {
    commons: {
      cancel: "Cancelar",
      confirm: "Confirmar",
      unknowError: "Error desconocido",
    },
    components: {
      authModal: {
        acceptTerms: 'Antes de continuar debes aceptar nuestros Términos & Condiciones y la Política de Privacidad',
      },
      buyButtons: {
        anotherCountry: "🌎 Otro país",
        purchaseNoConfirmed: "No se logró confirmar la compra",
        youMustEnter: "Debes ingresar una cantidad mayor a 1000 $FROIN",
        buyFroin: "Comprar  $FROIN",
        thankyou:
          "Gracias por tu interés en nuestro proyecto, por ahora solo estamos recibiendo inversiones en modalidad personalizada. \n\nPara hacer tu compra, escríbenos un correo a help@froin.cocon la cantidad de {{quantity}} $FROIN que deseas adquirir y el método de pago deseado, para que nuestro equipo te envíe el link de pago de la entidad {{labelMethod}} y te asigne los tokens en tu cuenta cuando se confirme la compra.\n\nPróximamente habilitaremos los pagos en la página web, gracias por la comprensión.",
        gotIt: "Entendido",
        paymentSent: "Solicitud de pago enviada",
        weSentNotification:
          "Hemos enviado una notificación a tu app de Nequi con número {{phone}}, revisa la información y confirma el pago paga desembolsar tus $FROIN.",
        ifPaymentNoAppear:
          'Si no te aparece la ventana de pago, presiona en el botón "Ir a pagar" para redirigirte a la plataforma de pagos.',
        gotToPay: "Ir a pagar",
        enterPhone: "Ingresa tu número de celular",
        invalidPhoneFormat: "El número de celular es incorrecto",
        weWaitingForPay:
          "Estamos esperando que continues con tu proceso, revisa la información y confirma el pago paga desembolsar tus $FROIN.",
      },
      header: {
        beforeContinue: "Antes de continuar ...",
        youWantLogout: "¿Deseas cerrar sesión?",
        home: "Inicio",
        receive: "Recibir",
        history: "Historial",
        send: "Enviar",
        logOut: "Cerrar sesión",
        ranking: "Ranking",
      },
      incognitoAlert: {
        forLiveExp: "Para vivir toda la experiencia",
        noUseIncognito: "no uses la navegación incógnita",
      },
    },
    pages: {
      home: {
        accessAccount: {
          loggedIntoAccount: "Has ingresado a tu cuenta",
          thankYou:
            "Gracias por ser parte de la comunidad FROIN, recibirás información actualizada de novedades y nuevos beneficios en los cuales estamos trabajando constantemente para ofrecerle a nuestra comunidad.",
          backHome: "Volver al inicio",
        },
        buyHome: {
          writeUs:
            "Escríbenos un correo a help@froin.co describiendo tu caso y, en el menor tiempo posible, nuestro equipo se contactará contigo.",
          enterValidEmail: "Ingresa un correo electrónico válido",
          formatInvalid: "El formato no es correcto",
          helloAgain: "Hola de nuevo! 👋 Si necesitas ayuda no dudes en",
          contactUs: "contactarnos aquí.",
          currentlyBalance: "Actualmente en tu cuenta tienes un saldo de:",
          canBuyMore:
            "Puedes comprar más tokens con los siguientes métodos de pago, ingresa la cantidad (números enteros), selecciona tu ubicación y verás la cantidad a pagar en cada opción habilitada:",
          youGoingBuy: "Vas a comprar una cantidad de:",
          howMany: "¿Cuántos $FROIN deseas?",
          checkEmail: "Revisa la bandeja de entrada de tu correo electrónico",
          andFollow: "y sigue las instruciones para ingresar.",
          enterEmail:
            "Ingresa tu correo electrónico para crear o acceder a tu cuenta",
          logIn: "INGRESAR",
          buyFroin: "COMPRAR $FROIN",
          pleaseEnterEmail:
            "Por favor ingresar tu email nuevamente, asegurate de que usas el mismo navegador y sin modo incógnito",
          investCrypto:
            "Invierte en el proyecto criptográfico que revolucionará la industria de eventos y entretenimiento",
          ourProject:
            "Nuestro proyecto propone crear una economía sustentable y atractivo a nuevos inversionitas, recompensando a la comunidad que participe y utilice nuestros servicios. Puedes ver más información a continuación:",
          investNow: "INVIERTE AHORA",
          invested: "invertidos",
          goal: "objetivo",
        },
        featuresHome: {
          benefits: "Beneficios",
          atFroin:
            "En FROIN nos preocupamos por ofrecer los mejores beneficios para nuestros usuarios, la comunidad es importante para nosotros y por eso al utilizar nuestros servicios puedes acceder de inmediato a todos los siguientes beneficios por utilizar FROIN.",
          weAreWallet: "Somos un WALLET digital",
          sendAndReceive:
            "Envía y recibe $FROIN a otros usuarios solo con su correo electrónico y mantén tu saldo seguro sin cuota de mantenimiento. Aquí recibirás también tus recompensas que vas generando al participar en nuestro Ranking",
          new: "¡Nuevo!",
          cashless: "Servicio CASHLESS",
          buyAndSell: "Compra y vende productos en la plataforma de",
          andReceive: "y recibe descuentos al pagar con $FROIN.",
          comingSoon: "Próximamente",
          yourMoney: "Tu dinero a la mano",
          ifWishWithdraw:
            "Si deseas retirar tu saldo de $FROIN a una cuenta de",
          or: "o",
          canContact: "puedes contactarnos a",
          weWillGladly:
            "y con gusto te ayudaremos en el proceso de recibirlo en menos de 24 horas (puede variar dependiendo del método de pago).",
          investEarn: "Invierte y gana",
          ourCommunity:
            "Nuestra comunidad puede invertir su saldo de $FROIN para generar más tokens de una forma pasiva. El porcentaje de recompensa APY puede variar entre el 6% al 20% de ganancias.",
          moreBenefits: "PRONTO MÁS BENEFICIOS POR ANUNCIAR",
        },
        bannerHome: {
          welcomeTitle: "Bienvenide a FROIN",
          welcomeDescription:
            "Somos el proyecto tecnológico que trabaja para revolucionar la experiencia en eventos y entretenimiento, basados en impulsar los emprendimientos para generar oportunidades a toda la comunidad",
          join: "Unirme ahora",
          introDescription:
            "Desde 2023 nos enfocamos en la idea de comenzar a crear productos digitales para revolucionar las experiencias digitales de la industria, por eso nace FROIN. Trabajamos con el objetivo de ofrecer servicios innovadores que puedan impulsar los proyectos, marcas, emprendimientos, negocios, y todo tipo de idea que pueda verse beneficiada.\n\nAl ser parte de FROIN, puedes participar de diferentes maneras dependiendo de tus objetivos.",
          suggestedVideos: "Contenido sugerido",
        },
        rolesInfo: {
          label: "¿Cómo puedo participar?",
          labelArtist: "Artista / Marca",
          descriptionArtist:
            "Es hora de llevarles nuevas experiencias a tu grupo de seguidores, fans, clientes, etc. Con los servicios de “PROMOCIÓN MULTIMEDIA” para impulsar tu proyecto, puedes ofrecer un programa de recompensas para cada usuario que interactue con tu contenido.",
          join: "Unirme ahora",
          labelViewer: "Espectador",
          descriptionViewer:
            "La gran parte importante de la industria de eventos y entretenimiento, que son los asistentes y espectadores, no se ve beneficiada de algún modo por su participación, por eso nace FROIN.\n\nSi eres un verdadero fan, seguidor o participante activo en la comunidad, comenzarás a recibir recompensas en $FROIN, las cuales se podrán redimir en compras de productos/servicios de nuestros comercios aliados, y próximamente por dinero real.",
        },
      },
      history: {
        historyList: {
          thereNo:
            "No hay transacciones para mostrar que hayas realizado últimamente",
          back: "Anterior",
          next: "Siguiente",
          transactionHistory: "Historial de transacciones",
          belowCanSee:
            "A continuación puedes ver el listado de tus transacciones con el detalle de cada una.\n\nSi necesitas soporte no dudes en escribir a nuestro",
          support: "canal de atención",
          buy: "Compra de tokens",
        },
      },
      page404: {
        weDidntFindPage: "No hemos encontrado la página que estas buscando",
        getMeOut: "Sácame de aquí",
      },
      receive: {
        receiveView: {
          receive: "Recibir",
          receiveTokens:
            "Para recibir tokens, puedes compartir tu correo eletrónico",
          orYouCan:
            "o pueden escanear el siguiente código QR.\n\nSi necesitas soporte no dudes en escribir a nuestro",
          support: "canal de atención",
          qrCode: "Código QR de tu wallet",
        },
      },
      send: {
        sendForm: {
          tokensSent: "Tokens enviados éxitosamente",
          enterAllData: "Ingresa todos los datos",
          amountGreater: "La cantidad debe ser mayor a 0 tokens",
          youCannotSend: "No puedes enviarte tokens",
          noHaveEnough: "No tienes suficientes tokens",
          sendFroin: "Enviar  $FROIN",
          doYouAgreeSend:
            "¿Estás de acuerdo en enviar {{quantity}} $FROIN al usuario con el correo electrónico {{email}}? La transacción fallará si el usuario no se encuentra registrado en la plataforma. \n\nRecuerda que esta acción no se puede reversar.",
          enterValidEmail: "Ingresa un correo electrónico válido",
          ourSendSystem: "Nuestro sistema de envío es",
          easyFast: "fácil, rápido y seguro",
          theOnlyThing:
            "lo único que debes hacer es ingresar la cantidad de tokens a enviar, agregar el correo del receptor y confirmar el envío.\n\nSi necesitas soporte no dudes en escribir a nuestro",
          support: "canal de atención",
          amountTokens: "Cantidad de tokens a enviar",
          howMany: "¿Cuántos $FROIN vas a enviar?",
          recipientEmail: "Correo electrónico del receptor",
        },
      },
    },
  },
};

export default texts;
