import { makeStyles } from "@mui/styles";
import { colors } from "styleguide";

const styles = makeStyles({
  infoTooltip: {
    '& h5': {
      color: colors.orange,
      fontSize: 24,
      fontWeight: '600',
      margin: '12px 0 6px 0',
    },
    '& p': {
      color: colors.white,
      fontSize: 18,
      fontWeight: '400',
      margin: 0
    },
  },
});

export default styles;
