import * as React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

import DataAuthorizationFile from "assets/documents/Autorizacion_Data.pdf";
import FroinProposalFile from "assets/documents/froinProposal.pdf";
import pageStyles from "./styles/renderLegalDocStyles";
import Model from "hooks/Model";
import Layout from "components/Layout";

const documents = {
  data: DataAuthorizationFile,
  artist: FroinProposalFile,
};

const RenderLegalDoc = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const nameDocument = urlParams.get("document") || "data";
  // const { t } = useTranslation();
  const styles = pageStyles();
  const [numPages, setNumPages] = React.useState(0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    console.log(numPages);
    setNumPages(numPages);
    Model.setData("loading", false);
  };

  React.useEffect(() => {
    Model.setData("loading", true);
    Model.setData("opacity", 0.7);

    pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
  }, []);

  return (
    <Layout withBg>
      <Document
        file={documents[nameDocument] || documents.data}
        onLoadSuccess={onDocumentLoadSuccess}
        className={
          nameDocument === "artist" ? styles.container2 : styles.container
        }
      >
        {new Array(numPages).fill("page_").map((value, index) => (
          <Page pageNumber={index + 1} key={value + index} />
        ))}
        <div
          className={styles.cover}
          style={{
            maxHeight: numPages * (nameDocument === "artist" ? 900 : 1035),
            height: `${numPages * (nameDocument === "artist" ? 75 : 129)}vw`,
          }}
        />
      </Document>
    </Layout>
  );
};

export default RenderLegalDoc;
