import { makeStyles } from "@mui/styles";
import { colors, Commons } from "../../../styleguide";

const styles = makeStyles({
  gridContainerVideo: {
    [Commons.smallQuery]: {
      flexDirection: "column-reverse !important",
      marginTop: 0,
    },
  },
  containerVideo: {
    backgroundColor: colors.gray50,
    overflow: "hidden",
    borderRadius: 18,
    border: `12px solid ${colors.orange}`,
    width: "50vw",
    height: "28vw",
    maxHeight: 680,
    maxWidth: 900,
    boxSizing: "border-box",
    [Commons.smallQuery]: {
      width: "100%",
      height: "auto",
      borderWidth: 6,
      aspectRatio: "3 / 2",
    },
  },
  iconPlayer: {
    color: colors.black,
    fontSize: "60px !important",
    backgroundColor: colors.orange,
    borderRadius: "50%",
    [Commons.smallQuery]: {
      fontSize: "42px !important",
    },
  },
  containerScrollInfo: {
    maxHeight: '24vw',
    overflowY: 'auto',
    minHeight: 150,
    [Commons.smallQuery]: {
      maxHeight: 'unset',
    },
  },
  nameVideo: {
    color: colors.white,
    fontSize: 36,
    fontWeight: 600,
    margin: "0px 0 18px 0",
    [Commons.smallQuery]: {
      fontSize: 30,
    },
  },
  imageAuthor: {
    minHeight: 120,
    minWidth: 120,
    maxHeight: 120,
    maxWidth: 120,
    backgroundColor: colors.white,
    borderRadius: "50%",
    objectFit: "cover",
    marginRight: 30,
    border: `3px solid ${colors.secondary}`,
    cursor: 'pointer',
    [Commons.smallQuery]: {
      minHeight: 90,
      minWidth: 90,
      maxHeight: 90,
      maxWidth: 90,
      marginRight: 18,
    },
  },
  nameAuthor: {
    color: colors.white,
    fontSize: 24,
    fontWeight: 500,
    margin: "0 0 6px 0",
    [Commons.smallQuery]: {
      fontWeight: 600,
      fontSize: 18,
    },
  },
  description: {
    color: colors.white,
    fontSize: 18,
    margin: 0,
    marginBottom: 48,
    [Commons.smallQuery]: {
      fontSize: 16,
    },
  },
  iconTotalRewards: {
    width: 42,
    height: 42,
    objectFit: "contain",
    transition: "all 0.2s",
  },
  loadPoint: {
    animation: "$fadeEffect 1s infinite",
  },
  totalRewards: {
    color: colors.white,
    fontSize: 54,
    fontWeight: 700,
    margin: "0 12px",
  },
  labelTotalRewards: {
    color: colors.orange,
    fontSize: 18,
    fontWeight: 400,
    margin: 0,
  },
  requestRewards: {
    ...Commons.defaultButton,
    ...Commons.flexRow,
    justifyContent: 'center',
    backgroundColor: colors.black,
    color: colors.orange,
    borderRadius: 12,
    fontWeight: '500',
    textAlign: 'center',
    padding: '6px 12px',
    fontSize: 18,
    border: `2px solid ${colors.orange}`,
    marginLeft: 36,
    opacity: 0.5,
    gap: 6,
    [Commons.smallQuery]: {
      marginLeft: 0,
      minWidth: '55vw',
      marginTop: 12
    },
  },
  availableReward: {
    backgroundColor: colors.orange,
    color: colors.white,
    border: `2px solid ${colors.white}`,
    opacity: 1,
  },
  rowRecommended: {
    ...Commons.flexRow,
    flexWrap: "nowrap",
    overflowY: "auto",
    marginBottom: 60,
    [Commons.smallQuery]: {
      marginBottom: 30,
    },
  },
  containerRecommenedVideo: {
    margin: "30px 60px 0 0",
    [Commons.smallQuery]: {
      marginRight: 18,
    },
  },
  playerRecommenedVideo: {
    height: 300,
    width: 420,
    backgroundColor: colors.gray50,
    borderRadius: 12,
    border: `6px solid ${colors.white}`,
    overflow: "hidden",
    [Commons.smallQuery]: {
      height: "auto",
      width: 280,
      aspectRatio: "3 / 2",
    },
  },
  nameAuthorRecommended: {
    color: colors.white,
    fontSize: 24,
    fontWeight: 500,
    margin: "18px 0 6px 0",
    [Commons.smallQuery]: {
      fontSize: 18,
    },
  },
  descriptionRecommended: {
    color: colors.white,
    fontSize: 18,
    margin: 0,
    [Commons.smallQuery]: {
      fontSize: 16,
    },
  },
  contentErrorVideo: {
    ...Commons.flexColumn,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  errorVideoIcon: {
    width: 120,
    height: "auto",
    [Commons.smallQuery]: {
      width: 90,
    },
  },
  errorVideoText: {
    width: '75%',
    color: colors.black,
    fontWeight: '600',
    textAlign: 'center',
    margin: '12px 0 0 0',
    fontSize: 16,
    [Commons.smallQuery]: {
      width: '90%',
      fontSize: 12,
    },
  },
  "@keyframes fadeEffect": {
    "0%": {
      opacity: 1,
    },
    "50%": {
      opacity: 0.5,
    },
    "100%": {
      opacity: 1,
    },
  },
});

export default styles;
