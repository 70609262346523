import * as React from "react";
import { useContext } from "react";
import { Backdrop, Grid } from "@mui/material";

import pageStyles from "./styles";
import { Store, StoreProps } from "hooks/main_store";

const Onboarding = () => {
  const { state } = useContext<StoreProps>(Store);
  
  const styles = pageStyles();


  return (
    <>
      <Backdrop
        open={false}
        className={styles.backdrop}
      />
      <div
        className={[
          styles.container,
          false ? styles.showContainer : "",
        ].join(" ")}
      >
        <Grid container>
          <Grid item>
            <img />
            <h5></h5>

          </Grid>
          <Grid item>

          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default Onboarding;
