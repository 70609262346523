import * as React from "react";

import { Header, Footer } from "components";
import pageStyles from "./styles";

function Layout({ children, withBg = false }) {
  const styles = pageStyles();

  return (
    <>
      <Header />
      <div className={[styles.children, withBg ? styles.withBg : ""].join(" ")}>
        {children}
      </div>
      <Footer />
    </>
  );
}

export default Layout;
