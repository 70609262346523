import * as React from "react";
import { Grid, Tooltip } from "@mui/material";
import * as moment from "moment";

import Logocrom from "assets/video/Logocrom.gif";
import { Layout, ElementsParallax } from "components";
import pageStyles from "./RankingStyles";
import { useRanking } from "services";

function RankingScreen() {
  const styles = pageStyles();

  const { currentScore, settings, currentPosition, listRanking } = useRanking({});

  const renderFinalDate = () => {
    if (settings?.finalDate) {
      const finalDate = moment(settings?.finalDate, "x");
      const currentDate = moment();
      const restDays = finalDate.diff(currentDate, "days")
  
      return restDays > -1 ? restDays : 0;
    }

    return '-'
  };

  const returnAproxReward = () => {
    if (settings?.accumulated && settings?.prize && currentScore) {
      return ((currentScore / settings.accumulated) * 100 * settings?.prize).toFixed(4);
    }

    return 0;
  };

  const renderUsers = () => {
    return listRanking.map((user, index) => (
      <div className={styles.itemUser} key={index}>
        <div>{index + 1}</div> <p>{user}</p>
      </div>
    ));
  };

  return (
    <Layout withBg>
      <ElementsParallax variant="leftBg" />
      <div className={styles.containerBanner}>
        <div className={styles.colInfo}>
          <div className={styles.infoContainer}>
            <h2>Ranking general</h2>
            <p>
              A continuación puedes encontrar la información sobre tu progreso
              en el ranking, comparar con el puntaje de otros usuarios, ver los
              premios y los dias restantes.
            </p>
          </div>
        </div>
        <div className={styles.imageRanking}>
          <div className={styles.containerImageLogo}>
            <img src={Logocrom} alt="FROIN" className={styles.imageLogo} />
          </div>
          <div className={styles.overlayVideo} />
        </div>
      </div>
      <Grid container className={styles.gridContainer}>
        <Tooltip title={`Días restantes: ${234}`}>
          <Grid item className={[styles.itemSquare, styles.itemSquareMidleMobile].join(' ')}>
            <div className={styles.divisionSquare}>
              <p className={styles.labelItemSquare}>Posición actual:</p>
              <p className={styles.valueItemSquare}>{currentPosition < 1 ? '+50' : currentPosition}</p>
            </div>
          </Grid>
        </Tooltip>
        <Tooltip title={`Días restantes: ${234}`}>
          <Grid item className={[styles.itemSquare, styles.itemSquareMidleMobile].join(' ')}>
            <div className={styles.divisionSquare}>
              <p className={styles.labelItemSquare}>Días restantes:</p>
              <p className={styles.valueItemSquare}>{renderFinalDate()}</p>
            </div>
          </Grid>
        </Tooltip>
        <Tooltip title={`FOINTS acumulados: ${currentScore}`}>
          <Grid item className={styles.itemSquare}>
            <div className={styles.divisionSquare}>
              <p className={styles.labelItemSquare}>FOINTS acumulados:</p>
              <p className={styles.valueItemSquare}>{currentScore}</p>
            </div>
            <div className={styles.divisionSquare}>
              <p className={styles.labelItemSquare}>Total FOINTS comunidad:</p>
              <p className={styles.valueItemSquare}>{settings?.accumulated || 0}</p>
            </div>
          </Grid>
        </Tooltip>
        <Tooltip title={`Premio acumulado: ${settings?.prize}} $FROIN`}>
          <Grid item className={styles.itemSquare}>
            <div className={styles.divisionSquare}>
              <p className={styles.labelItemSquare}>
                Premio acumulado en $FROIN:
              </p>
              <p className={styles.valueItemSquare}>{settings?.prize}</p>
            </div>
            <div className={styles.divisionSquare}>
              <p className={styles.labelItemSquare}>Tu premio actual es de:</p>
              <p className={styles.valueItemSquare}>{returnAproxReward()} </p>
            </div>
          </Grid>
        </Tooltip>
        <Grid item className={styles.itemSquare}>
          <p className={styles.labelItemSquare}>Primeros 50 usuarios</p>
          <div className={styles.usersList}>{renderUsers()}</div>
        </Grid>
        <Grid item className={styles.itemSquare}>
          <p className={styles.labelItemSquare}>Términos y condiciones</p>
          <div className={styles.scrollTerms}>
            <p className={styles.textTerms}>
              Lorem, ipsum dolor sit amet consectetur adipisicing elit. Minima,
              magnam aperiam? Sint sapiente minus veritatis quas consectetur
              molestias est officiis aliquid modi. Sequi quisquam saepe et
              obcaecati reprehenderit. Sit, accusamus!
              <br />
              <br />
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Quae
              maiores voluptas commodi tempora cum facilis? Maiores, sint
              maxime, quasi aliquid voluptatem mollitia suscipit quisquam vero
              pariatur impedit ratione? Alias, quos.
              <br />
              <br />
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore
              modi optio perferendis. Accusamus hic impedit dolor, dolorem magni
              est deleniti, animi unde maxime possimus culpa eligendi, qui optio
              sint? Consequuntur!
            </p>
          </div>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default RankingScreen;
