import * as React from "react";
import { Tooltip } from "@mui/material";
import pageStyles from "./styles";

const TooltipComponent = ({ children, title, text }) => {
  const styles = pageStyles();

  return (
    <Tooltip title={<div className={styles.infoTooltip}>
        <h5>{title}</h5>
        <p>{text}</p>
      </div>
    }>
      {children}
    </Tooltip>
  );
}

export default TooltipComponent;
