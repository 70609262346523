import { useEffect, useState } from "react";

import {usedImages} from 'utils/usedImages';
import Model from "hooks/Model";

const PreLoadImagesService = () => {
  const [loading, setLoading] = useState(true);

  const preloadImages = async () => {
    try {
      Model.setData("loading", true);
      setLoading(true)

      await Promise.all(usedImages.map(src => new Promise((resolve) => {
        const img = new Image();
  
        img.src = src;
        img.onload = () => resolve(img);
        img.onerror = () => resolve(img);
      })));
    } catch (error) {
      console.log('PreLoadImages error: ', error)
    } finally {
      setLoading(false)
    }
  }


  useEffect(() => {
    preloadImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading
  }
}

export default PreLoadImagesService;
